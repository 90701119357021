import { Injectable } from '@angular/core';
import { GlobalService } from './global.service';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class DriveService {
  navigationState: number = 0;
  campusDetails: any = null;
  campus: any = null;
  driveTypes: any = null;
  companyCategories: any = null;
  companies: any = null;
  salaryLimit = 1000000000000;

  constructor(private globalService: GlobalService, private http: HttpService) { }
  
  async getDrives(payload: any) {
    let result = await this.http.post(`/drives/getDrives`, payload);
    return [result.success, result.data];
  }

  async getDrivesForReminders(payload: any) {
    let result = await this.http.post('/drives/getDrivesForReminders', payload);
    return result;
  }
  
  async getSpecificDrive(payload: any) {
    let result = await this.http.post(`/drives/getSpecificDrive`, payload);
    return result.data;
  }

  async fetchAccounts(group_account_id: string) {
    if(!this.campus) {
      const response = await this.globalService.fetchAccounts(group_account_id);
      this.campus = response.data;
    }

    return this.campus;
  }

  async fetchCampusDetails(group_account_id: string) {
    if(!this.campusDetails) {
      const response = await this.globalService.fetchCampusDetails(group_account_id);
      this.campusDetails = response.data;
    }

    return this.campusDetails;
  }

  async getDriveTypes() {
    if(!this.driveTypes) {
      this.driveTypes = await this.globalService.getDriveTypes();
    }

    return this.driveTypes;
  }

  async getCompanyCategories() {
    if(!this.companyCategories) {
      this.companyCategories = await this.globalService.getCompanyCategories();
    }

    return this.companyCategories;
  }
  
  async getCompanies() {
    if(!this.companies) {
      this.companies = await this.globalService.getCompanies();
    }

    return this.companies;
  }

  async sendDriveReminder(payload: any)
  {
    return await this.http.post('/republish/sendDriveReminder', payload);
  }

  clearCache() {
    this.campus = null;
    this.campusDetails = null;
    this.driveTypes = null;
    this.companyCategories = null;
    this.companies = null;
  }

  async endDrive(payload: any) {
    return await this.http.post('/drives/endDrive', payload);
  }

  async driveHoldStatusChange(payload: any) {
    return await this.http.post('/drives/driveHoldStatusChange', payload);
  }

  async drivePublish(payload: any) {
    const publishDrive = await this.http.post('/republish/publishDrive', payload);
    return publishDrive;
  }

  async addDrive(payload: any) {
    return await this.http.post('/drives/add', payload);
  }

  async updateDrive(drive_id: string, payload: any) {
    return await this.http.put(`/drives/update/${drive_id}`, payload);
  }

  async fetchCriteria(drive_id: string) {
    return await this.http.post('/drives/fetchFilterDetails', { drive_id });
  }

  async cloneDrive(payload: any) {
    return await this.http.post('/drives/cloneDrive', payload);
  }

  async deleteDrive(payload: any) {
    return await this.http.post('/drives/deleteDrive', payload);
  }

  async getCountForDriveListing(payload: any) {
    return await this.http.post('/drives/getCountForDriveListing', payload);
  }

  async getPlacedCountUserWise(payload: any) {
    return await this.http.post('/drives/getPlacedCountUserWise', payload);
  }

  async driveDataDownload(payload: any) {
    return await this.http.post('/drives/driveDataDownload', payload);
  }

  async driveClashIndicator(payload: any) {
    return await this.http.post('/drives/driveClashIndicator', payload);
  }

  async saveDriveCriteria(payload: any) {
    return await this.http.post('/drives/saveDriveCriteria', payload);
  }

  async getCompanyLogo(search: string) {
    const data = await fetch('https://autocomplete.clearbit.com/v1/companies/suggest?query=' + search);
    return await data.json();
  }

  async driveSpecificNotification(payload: { drive_id: string , sendNotification: boolean }) {
    return await this.http.post(`/drives/driveSpecificNotification`, payload);
  }

  async checkDriveInRepublishQueue(drive_id: string) {
    const response = await this.http.post('/republish/checkDriveInRepublishQueue', { drive_id });
    return response.data;
  }

  async checkStudentPlacedInDrive(drive_id: string) {
    const response = await this.http.post('/drives/checkStudentPlacedInDrive', { drive_id });
    return response.data;
  };
}
