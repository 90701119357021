import { Component, ElementRef, Input, Output, EventEmitter, ViewChild, TemplateRef, ChangeDetectorRef, HostListener } from '@angular/core';
import { table, Eglobal } from '@enum'
import { btnProperties } from '@models'
import { GlobalService } from '@services';
import { manuallyAdded, setTableDefaultIcon, notificationPdf, addStudentsIcon, removeStudentsIcon } from '@assets';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.css']
})
export class TableComponent {

  @ViewChild('leftIcons') leftIcons!: ElementRef;
  @ViewChild('openAction') openAction: any;
  @Input() searchBy: string = ''
  @Input() dataList: any[] = [];
  @Input() loadingSpinner: boolean = false;
  @Input() columns: any[] = [];
  @Input() actionOptions: any[] = [];
  @Input() dataCount = 0;
  @Input() requestCount = 0;
  @Input() actionToSelect: any[] = []
  @Input() pageLimits: any[] = [];
  @Input() eventListen: boolean = true;
  @Input() captionsList!: any;
  @Input() tableheight: string = '';
  @Input() remValue: string = '18';
  @Input() totalPage: any;
  @Input() filterData!: { [key: string]: any[] }
  @Input() actionTemplate?: TemplateRef<any>;
  @Input() studentStatusTemplate?: TemplateRef<any>;
  @Input() studentReasonTemplate?: TemplateRef<any>;
  @Input() academicDataTemplate?: TemplateRef<any>;
  @Input() driveCountTemplate?: TemplateRef<any>;
  @Input() offerTemplate?: TemplateRef<any>;
  @Input() confirmationTemplate?: TemplateRef<any>;
  @Input() buttonTemplate?: TemplateRef<any>;
  @Input() customInputTemplate?: TemplateRef<any>;
  @Input() linkTemplate?: TemplateRef<any>;
  @Input() republishTemplate?: TemplateRef<any>;
  @Input() isCheckboxSelected?: boolean = false;
  @Input() isLoading?: boolean = false;
  @Input() dlCustomField?: boolean = false;
  @Input() isShowSelectedAll = false;
  @Input() checkBoxSelectedCondition = false;
  @Input() hideTemplate = false;
  @Input() typeOfModule = '';
  @Input() showDownload = false;
  @Input() additionalOptions: any[] = [];
  @Input() fillTableDataOnLoad = false;
  @Input() manually_actions = false;

  @Output() pageNo = new EventEmitter<any>();
  @Output() rowLength = new EventEmitter<any>();
  @Output() search = new EventEmitter<any>();
  @Output() searchChange = new EventEmitter<any>();
  @Output() sortBy = new EventEmitter<any>();
  @Output() applyFilters = new EventEmitter<any>()
  @Output() selectedColumns = new EventEmitter<any>()
  @Output() fileFormat = new EventEmitter<any>()
  @Output() deletingData = new EventEmitter<any>()
  @Output() openEdit = new EventEmitter<any>()
  @Output() rowClicked = new EventEmitter<any>();
  @Output() changeRequest = new EventEmitter<any>();
  @Output() checkboxStateChange = new EventEmitter<any>();
  @Output() downloadCustomField = new EventEmitter<any>();
  @Output() tableActionClick = new EventEmitter<any>();
  @Output() dropDownAction = new EventEmitter<{ type: string, value: string, student_data: any[], selectAll: boolean }>();
  @Output() downloadEvent = new EventEmitter<any>();
  @Output() tableOptionsEvent = new EventEmitter<any>();
  @Output() setTableDefaultsEvent = new EventEmitter<any>();
  @Output() additionalOptionChange = new EventEmitter<any>();

  leftSidebarName: string = '';
  opVisible: boolean = false
  downloadColumnSelectAll: boolean = false
  showList: boolean = false;
  showFilter: boolean = false;
  searchValue: any = '';
  searchColor: boolean = true;
  selectedOptions: any[] = [];
  pageSize: number = 10;
  isPreviousButtonDisabled: boolean = false;
  isNextButtonDisabled: boolean = false;
  currentPage: number = 1;
  tableEnum: any = table;
  globalEnum: any = Eglobal;
  sortIconVisible: { [key: string]: boolean } = {};
  checkedAll: boolean = false;
  selectedAll: boolean = false;
  shouldShowTooltip = false;
  afterView: boolean = false;
  tooltips: { [key: string]: string } = {};
  selectedFilterOptions: any = {};
  checkedColumns: any[] = [];
  downloadColumnAs: string = 'excel';
  btnProperties: btnProperties = {
    btnName: 'Download',
    fontColor: 'blue',
    background: 'white',
    border: '1px solid blue',
    width: '100px',
    fontWeight: 'bold',
    fontSize: '14px',
    height: '30px',
    borderRadius: '5px',
    marginleft: '0px',
    padding: '0px 10px'
  }
  btnLoading: boolean = false;
  showSetDefaultDialog: boolean = false;
  setTableDefaultIcon = setTableDefaultIcon;

  manuallyAdded = manuallyAdded;
  notificationPdf: string = notificationPdf;
  addStudentsIcon: string = addStudentsIcon;
  removeStudentsIcon: string = removeStudentsIcon;
  sortField = '';
  sortOrder = 1;

  constructor(
    private cdr: ChangeDetectorRef,
    public globalService: GlobalService,
  )
  {
  }

  isTruncated(element: HTMLElement): boolean {
    return element.scrollWidth > element.clientWidth;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.calculateTableHeight();
  }

  ngOnChanges(_changes: any) {
    if (_changes["eventListen"]) {
      this.columns.forEach(element => {
        element.selected = false
      });
      this.selectedOptions = [];
      this.checkedColumns = []
    }
    if (_changes["columns"]) {
      _changes["columns"].currentValues = this.columns
    }
    if (_changes["dataList"] && this.fillTableDataOnLoad) {
      this.fillTableData(_changes["dataList"].currentValue)
    }
  }

  private calculateTableHeight() {
    const remInPx = 12; // Assuming the base font size is 16px
    const remValueInNumber = parseFloat(this.remValue); // Convert remValue string to number
    const remValueInPx = remValueInNumber * remInPx;
    const windowHeight = window.innerHeight;
    const heightInPx = windowHeight - remValueInPx;

    this.tableheight = `${heightInPx}px`;
    this.cdr.detectChanges();
  }

  ngAfterViewInit() {
    this.calculateTableHeight();
    this.cdr.detectChanges()
  }

  onClickAction(option: any) {
    this.openAction.hide();
    switch (option.value) {
      case 'edit': this.openEditFiled();
        break;
      case 'delete': this.deleteData();
        break;
    }
  }

  downloadColumnAll(event: any) {
    if (event.checked) {
      this.columns.forEach(element => {
        element.selected = true
        this.checkedColumns.push(element)
      });
    }
    else {
      this.columns.forEach(element => {
        element.selected = false
        this.checkedColumns = []
      });
    }
  }

  onSearchChange(event: any) {
    let searchValue = event.target.value
    this.searchColor = searchValue.length > 0;
    this.searchChange.emit(searchValue)
  }

  openEditFiled() {
    this.openEdit.emit(this.selectedOptions);
  }

  rowLimit(event: any) {
    this.checkedAll = false
    this.resetSelected();
    this.currentPage = 1;
    this.rowLength.emit(event.value);
  }

  previousPagination(event: any) {
    if (this.currentPage == 1) {
      this.isPreviousButtonDisabled = true;
      return;
    } else {
      this.isPreviousButtonDisabled = false;
    }
    this.currentPage = this.currentPage - 1;
    this.resetSelected();
    this.pageNo.emit(this.currentPage);
  }

  nextPagination(event: any) {
    if (this.currentPage == this.totalPage || this.totalPage == 0) {
      this.isNextButtonDisabled = true;
      return;
    } else {
      this.isNextButtonDisabled = false;
    }
    this.currentPage = this.currentPage + 1;
    this.resetSelected();
    this.pageNo.emit(this.currentPage);
  }

  closeFilters() {
    this.openAction.hide();
    this.showFilter = false;
    this.showList = false;
    this.downloadColumnSelectAll = false
  }

  openlist(name: string) {
    this.closeFilters();
    if (name === this.leftSidebarName) {
      this.leftSidebarName = '';
      return;
    }
    this.showList = true
    this.leftSidebarName = name
    if (this.showFilter) {
      this.showFilter = !this.showFilter
    }
  }

  openDownload(name: string) {
    if (this.showDownload === true) {
      return this.downloadEvent.emit();
    }

    if (name === this.leftSidebarName) {
      this.closeFilters();
      this.leftSidebarName = '';
      return;
    }
    this.columns.forEach((column: any) => column.selected = false);
    this.showList = true
    this.leftSidebarName = name
    if (this.showFilter) {
      this.showFilter = !this.showFilter
    }
  }

  openFilter() {
    this.closeFilters();
    this.showFilter = true
  }

  tableOptions(type: string) {
    this.tableOptionsEvent.emit(type);
  }

  onMouseEnter(columnField: string) {
    this.sortIconVisible[columnField] = true;
  }

  onMouseLeave(columnField: string) {
    this.sortIconVisible[columnField] = false;
  }

  checkAll(event: any) {
    this.selectedOptions = [];
    for (const q of this.dataList) {
      q.selected = event.checked;
      if (event.checked) {
        this.selectedOptions.push(q);
      }
    }
    this.checkedAll = event.checked;
    this.checkChkBoxSelected();
    this.selectedAll = false;
  }

  checkedrow(event: any) {
    if (event.selected) {
      this.selectedOptions.push(event);
    } else {
      const i = this.selectedOptions.indexOf(event);
      if (i !== -1) {
        this.selectedOptions.splice(i, 1);
      }
    }
    this.checkedAll = this.selectedOptions.length === this.dataList.length;
    this.checkChkBoxSelected();
  }

  private checkChkBoxSelected() {
    if (this.checkBoxSelectedCondition) {
      this.isCheckboxSelected = this.selectedOptions.length > 0;
      this.checkboxStateChange.emit({
        isCheckboxSelected: this.isCheckboxSelected,
        selectedOptions: this.selectedOptions
      });
    } else {
      this.checkboxStateChange.emit({
        selectedOptions: this.selectedOptions
      });
    }
  }

  cancelFilterOptions() {
    this.showFilter = false;
  }

  checkFilterOptions(event: any, key: any, options: any, data: any) {
    if (event.checked) {
      options.selected = event.checked
      this.selectedFilterOptions[key] = this.selectedFilterOptions[key] || [];
      this.selectedFilterOptions[key].push(options.value);
    }
    else {
      this.selectedFilterOptions[key] = this.selectedFilterOptions[key].filter((arr: any) => arr !== options.value)
      if (this.selectedFilterOptions[key].length === 0) {
        delete this.selectedFilterOptions[key]
      }
    };
    options.selected = event.checked
  }

  filtersApply() {
    this.showFilter = false
    this.applyFilters.emit(this.selectedFilterOptions)
  }

  checkColumnName(event: any) {
    if (event.selected) {
      this.checkedColumns.push(event);
    } else {
      this.downloadColumnSelectAll = false
      const i = this.checkedColumns.indexOf(event);
      if (i !== -1) {
        this.checkedColumns.splice(i, 1);
      }
    }
  }

  downloadColumnsAs() {
    this.fileFormat.emit(this.downloadColumnAs)
    this.selectedColumns.emit(this.checkedColumns);
  }

  searchValueChange(event: any) {
    this.searchValue = event.target.value;
    this.currentPage = 1;
    this.search.emit(this.searchValue);
  }

  deleteData() {
    this.deletingData.emit(this.selectedOptions)
  }

  onRowClick(event: any) {
    this.rowClicked.emit(event);
  }

  downloadCustomFieldData() {
    this.downloadCustomField.emit(this.selectedOptions);
  }

  checkAllToFalse() {
    this.selectedOptions = [];
    this.checkedAll = false;
    for (const q of this.dataList) {
      q.selected = false;
    }
  }

  checkSelectedAll() {
    if (this.selectedAll) {
      this.checkAllToFalse()
    }
    else {
      this.checkAll({ checked: true });
    }
    
    this.selectedAll = !this.selectedAll;
  }

  selectedOptionInAction(event: any, item: any) {
    if (this.selectedOptions.length === 0) {
      return;
    }

    const { value } = event.value;

    this.dropDownAction.emit({
      value,
      student_data: this.selectedOptions,
      selectAll: this.selectedAll,
      type: item.item_type
    });

    this.selectedAll = false;

    setTimeout(() => {
      item.item = '';
    }, 0)
  }

  clickAction(type: string) {
    if(type === "changeRequest")
    {
      this.changeRequest.emit();
    }
    if(this.selectedOptions.length === 0) {
      return;
    }
    this.tableActionClick.emit({
      type,
      student_data: this.selectedOptions,
      selectAll: this.selectedAll
    });
    this.selectedAll = false;
  }

  clickSendEmail(type: string) {
    this.tableActionClick.emit({
      type,
      student_data: this.selectedOptions,
      selectAll: this.selectedAll
    });
    this.selectedAll = false;
  }

  toggleOverlayPanel() {
    this.opVisible = !this.opVisible; // Toggle the value of opVisible
  }

  hideOverlay() {
    this.opVisible = false
  }

  onChange(event: any) {
  }

  resetTable() {
    this.searchValue = '';
    this.selectedOptions = [];
    this.checkedAll = false;
    this.checkedColumns = [];
    this.selectedFilterOptions = {};
    this.downloadColumnSelectAll = false;
    this.showFilter = false;
    this.showList = false;
    this.selectedAll = false;
  }

  resetSelected() {
    this.selectedOptions = [];
    this.checkedAll = false;
    this.selectedAll = false;
    this.checkAllToFalse();
  }

  showSetTableDefaults() {
    this.showSetDefaultDialog = true;
  }

  setTableDefaults() {
    this.setTableDefaultsEvent.emit(true);
    this.showSetDefaultDialog = false;
  };

  additionalOptionOnChange(_item: any) {
    this.additionalOptionChange.emit(this.selectedOptions);
  };

  fillTableData(dataList: any) {
    const selectedOptions = [];
    for (const q of dataList) {
      if (q.selected) {
        selectedOptions.push(q);
      }
    };
    this.selectedOptions = selectedOptions;
    this.checkedAll = selectedOptions.length !== 0 ? this.selectedOptions.length === this.dataList.length : false;
  }

  customSort(event: any) {
    if (event.field === this.sortField && event.order === this.sortOrder) {
      return;
    }

    this.sortField = event.field;
    this.sortOrder = event.order;
    this.sortBy.emit(event)
  }

  async onClickFile(data: any, field: any) {
    const prefix = window.location.origin;
    if(field === 'resume_link') {
      const url = `${prefix}/student-resume?u_id=${data.user_id}`
      window.open(url, '_blank');
    }
    else if(field === 'resume') {
      // interview process redirect
      const url = `${prefix}/student-resume?u_id=${data.user_id}&d_id=${data.drive_id}`
      window.open(url, '_blank');
    }
    else {
      const signedUrl = await this.globalService.getSignedUrl({
        bucketName: this.globalService.studentBucket,
        fileName: data[field],
      });

      window.open(signedUrl.data.response, '_blank')
    }
  
  }
}
