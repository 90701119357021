import { MessageService, ConfirmationService } from 'primeng/api';
import { Component, Input, TemplateRef, ViewChild, ElementRef } from '@angular/core';
import { btnProperties, iconsList, action } from '@models';
import { DriveService, GlobalService, FileUploadService } from '@services';
import {
  Rounds,
  Students,
  SBtnProperties,
  RBtnProperties,
  GBtnProperties,
  addRemoveStudents,
  DriveStudentStageDisplay,
  PatDriveStatus,
  PassoutYearsOptions,
  Severity,
  Summary,
  Message,
  DriveStudentStageMove,
  InterViewProcessTabs
} from '@enum';
import { TableComponent } from 'src/app/shared/components/table/table.component';
import { RoundsService } from 'src/app/core/services/rounds.service';
import {
  eligiblieBlack,
  eligiblieWhite,
  optoutActive,
  optoutInactive,
  optinActive,
  optinInactive,
  placedActive,
  placedInactive,
  removedActive,
  removedInactive,
  filterIcon,
  errorIcon,
  rightArrow,
  notificationPdf,
  waitingInactive,
  notEligibleInactive,
  waitingActive,
  notEligibleActive
}
  from '@assets';
import { AbstractControl, FormControl, ValidatorFn, Validators } from '@angular/forms';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
type MoveRoundsType = {
  drive_id: string;
  company_name: string;
  drive_number: string;
  drive_name: string;
  from_stage: string;
  to_stage?: string;
  status?: number;
  student_data: any[];
  group_account_id: string;
  done_by: string;
  selectAll: boolean;
  reason: string;
  send_email: boolean;
  filters: {},
  limit: number,
  page: number,
  search: string,
  company_category?: string;
  company_id?: string;
  selected_profiles?: string[];
  drive_objective?: string;
  sort_by: string;
  sort_order: number;
  active_tab: string;
}

@Component({
  selector: 'app-rounds',
  templateUrl: './rounds.component.html',
  styleUrls: ['./rounds.component.css']
})
export class RoundsComponent {
  @ViewChild('fileInput') fileInputRef!: ElementRef<HTMLInputElement>;
  @ViewChild('statusTemplate') statusTemplateRef!: TemplateRef<any>;
  @ViewChild('offerTemplate') offerTemplateRef!: TemplateRef<any>;
  @ViewChild('confirmationTemplate') confirmationTemplateRef!: TemplateRef<any>;
  @Input() onlyPlaced: boolean = false;
  @Input() driveData: any = {};
  @Input() driveStatus: string = '';
  @ViewChild(TableComponent) tableComponent!: TableComponent;
  uploadFiles: boolean = false
  opVisible: boolean = false
  group_account_id: string = '';
  isLoading: boolean = false;
  accountData: any = localStorage.getItem('account_details')
  userData: any = localStorage.getItem('user_details')
  accountMetaData: any = localStorage.getItem('account_metadata')
  profileOptions: any[] = []
  selectedProfile: any
  clicked: boolean = false;
  actionOptions: any[] = addRemoveStudents
  studentsEnum: any = Students
  rbtnProperties: { [key: string]: btnProperties } = RBtnProperties
  btnProperties: { [key: string]: btnProperties } = SBtnProperties
  gbtnProperties: { [key: string]: btnProperties } = GBtnProperties
  roundsOptions: action[] = []
  activeTab: InterViewProcessTabs = 'eligible';
  roundsEnum: any = Rounds;
  searchValue: string = '';
  actionToSelect: any[] = []
  loader: boolean = false
  tableLoader: boolean = false;
  sendemailslider: boolean = false;
  studentDataList: any[] = [];
  selectAllStudentCount: number = 0;
  selectAllStudents: boolean = false;
  driveMailInfo: any = {};
  columns: any[] = [];
  captionsList: any = { actions: false, columns: true, download: true, filter: false, search: true, pagination: true, header: true, limit: true, checkbox: true, manual_actions: true, custom_sort: true, sendEmail: true  }
  pageLimits: any[] = [{ label: 10, value: 10 }, { label: 25, value: 25 }, { label: 50, value: 50 }, { label: 100, value: 100 }];
  tableHeight: string = '63vh'
  totalPage: number = 0;
  rowsize: number = 10;
  selectedPage: any = 1;
  dataList: any[] = [];
  dataCount: number = 0
  visible: boolean = false
  emailOrReg: any[] = []
  selectedCategory: any;
  categories: any[] = []
  fieldMaps: any = {};
  selectedFilters: any = {
    campus: [],
    department: [],
    degreeSpec: [],
    studentStatus: [],
    passOutYear: []
  };
  filterData: any = [
    {
      filterName: "Campus",
      key: 'campus',
      array: []
    },
    {
      filterName: "Pass Out",
      key: 'passOutYear',
      array: PassoutYearsOptions
    },
    {
      filterName: "Student Status",
      key: 'studentStatus',
      array: []
    },
    {
      filterName: "Department",
      key: 'department',
      array: []
    },
    {
      filterName: "Degree",
      key: 'degreeSpec',
      array: []
    },
  ];
  openSideBarPurpose: boolean = false
  filtersCount: number = 0
  sideBarPurpose: string = ''
  studentCampusOptions: any[] = []
  campusDetails: any[] = [];
  filteredDepartments: any[] = [];
  showMoveStudents: boolean = false;
  sendEmail: boolean = false;
  reasonForMovingRound: string = '';
  offerTypeProperty: any;
  selectedOptions: any[] = []
  offerTypeFor: any = {
    salary: false,
    stipend: false
  }
  pdfSrc: string = ''
  opt_out_label: string = 'Opt-Out';
  opt_in_label: string = 'Opt-In';
  optout_past: string = 'Opted Out';
  optin_past: string = 'Opted In';
  salary_label: string = 'Salary';
  resume: File[] = [];
  roundActions: any[] = [];
  uploadingFile: boolean = false;
  filePath: any;
  fileUploadEvent: any
  offerLetterOrConfirmation: string = ''
  bucketData: any = {};
  fileBase64: any;
  errorData: any[] = [];
  showDownData: boolean = false;
  downloadColumnAs = 'excel';
  allColumns: any = [];
  filterIcon: string = filterIcon;
  errorIcon: string = errorIcon;
  rightArrow: string = rightArrow;
  notificationPdf: string = notificationPdf;
  selectedProfileForm!: FormControl
  priorityArray: string[] = []
  search: string = 'Email or Registration Number';
  downloadCategories: any = [
    { header: 'Basic info', data: [], isSelectedAll: false },
    { header: 'Academic info', data: [], isSelectedAll: false },
    { header: 'Additional info', data: [], isSelectedAll: false },
    { header: 'Download Resume', data: [], isSelectedAll: false, type: 'resume'},
    { header: 'Optional Form', data: [], isSelectedAll: false },
  ];

  driveStudentStageDisplay = DriveStudentStageDisplay;
  sort_by = 'primary_email';
  sort_order = 1;
  setOfferTypePayload: any = {}
  moveStudentsActions: {
    student_data: any[],
    selectAll: boolean,
    active_tab: string,
    stage: string,
    to_stage: InterViewProcessTabs | null,
  } = {
      student_data: [],
      selectAll: false,
      active_tab: 'Eligible',
      stage: 'eligible',
      to_stage: null
  };

  constructor(
    public globalService: GlobalService,
    private messageService: MessageService,
    private driveService: DriveService,
    private confirmationService: ConfirmationService,
    private fileUploadService: FileUploadService,
    private roundsService: RoundsService
  ) {
    this.formatData();
    this.accountData = JSON.parse(this.accountData)
    this.userData = JSON.parse(this.userData)
    let fields = this.globalService.formatFields(this.accountMetaData.field_labeling);
    const { academic_info, basic_info, additional_info } = fields;
    this.fieldMaps = { ...academic_info, ...basic_info, ...additional_info };

    this.allColumns = this.columns;

    this.selectedCategory = 'Email'
    this.categories = ['Email', 'Registration Number']
  }

  formatData() {
    this.globalService.driveLabelSubject.subscribe((label) => {
      const accountMetaData = localStorage.getItem('account_metadata');
      this.accountMetaData = JSON.parse(accountMetaData ?? '{}');
      let fields = this.globalService.formatFields(this.accountMetaData.field_labeling);

      const { academic_info, basic_info, additional_info } = fields;
      this.fieldMaps = { ...academic_info, ...basic_info, ...additional_info };

      this.search = `${this.fieldMaps['Email']} or ${this.fieldMaps['Registration Number']}`;

      const { optin_past, optout_past } = this.globalService.getLabels(label.labeling);
      const { opt_in_label, opt_out_label, salary_label } = label.labeling;
      this.opt_out_label = opt_out_label;
      this.opt_in_label = opt_in_label;
      this.optin_past = optin_past;
      this.optout_past = optout_past;
      this.salary_label = salary_label;

      this.roundsOptions = [
        { label: 'Eligible', value: 'eligible', isActive: true, count: 0, icon1: eligiblieWhite, icon2: eligiblieBlack },
        { label: `${optin_past}`, value: 'optIn', isActive: false, count: 0, icon1: optinActive, icon2: optinInactive },
        { label: `${optout_past}`, value: 'optOut', isActive: false, count: 0, icon1: optoutActive, icon2: optoutInactive },
        { label: 'Waiting', value: 'waiting', isActive: false, count: 0, icon1: waitingActive, icon2: waitingInactive },
        { label: 'Placed', value: 'placed', isActive: false, count: 0, icon1: placedActive, icon2: placedInactive, spacer: true },
        { label: 'Removed', value: 'removed', isActive: false, count: 0, icon1: removedActive, icon2: removedInactive },
        { label: 'Not Eligible', value: 'notEligible', isActive: false, count: 0, icon1: notEligibleActive, icon2: notEligibleInactive },
      ];

      this.roundActions = [
        { label: 'Eligible', value: 'eligible' },
        { label: `${opt_in_label === 'Apply' ? 'Applied' : 'Opt In'}`, value: 'optIn' },
        { label: 'Placed', value: 'placed' }
      ];

      this.driveStudentStageDisplay = {
        'eligible': 'Eligible',
        'optIn': `${opt_in_label === 'Apply' ? 'Applied' : 'Opt In'}`,
        'optOut': `${opt_out_label === 'Decline' ? 'Declined' : 'Opt Out'}`,
        'waiting': 'Waiting',
        'placed': 'Placed',
        'removed': 'Removed',
        'notEligible': 'Not Eligible'
      };

      this.columns = [
        { field: '', header: '', freezable: true, sortable: false, tableDefault: true, width: '3vw', type: 'pen_icon' },
        { field: 'primary_email', header: this.fieldMaps['Email'] ?? 'Emails', freezable: true, sortable: true, tableDefault: true, width: '15vw' },
        { field: 'registration_number', header: this.fieldMaps['Registration Number'] ?? 'Registration Number', freezable: false, sortable: true, tableDefault: true, width: '12vw' },
        { field: 'drive_status', header: 'Status', freezable: false, sortable: false, tableDefault: true, width: '11vw', padding: '0.4rem', type: 'driveStatus' },
        { field: 'first_name', header: 'Name', freezable: false, sortable: true, tableDefault: true, width: '12vw', combineCols: 'last_name', type: 'combine' },
        { field: 'campus', header: 'Campus', freezable: false, sortable: false, tableDefault: true, width: '14vw' },
        { field: 'pass_out_year', header: 'Pass Out Year', freezable: false, sortable: false, tableDefault: true, width: '12vw' },
        { field: 'department', header: 'Department', freezable: false, sortable: false, tableDefault: true, width: '15vw' },
        { field: 'degree_specialization', header: 'Degree & Specialization', freezable: false, sortable: false, tableDefault: true, width: '20vw' },
        { field: 'specialization_minor', header: 'Specialization Minor', freezable: false, sortable: false, tableDefault: true, width: '13vw' },
        { field: 'resume', header: 'Resume Link', freezable: false, sortable: false, tableDefault: true, width: '8vw', type: 'link' },
        { field: 'selected_profiles', header: 'Interested Profile', freezable: false, sortable: false, tableDefault: true, width: '15vw' },
      ];
    })
  }

  async ngOnInit() {
    this.group_account_id = this.accountData.group_account_id
    this.profileOptions = this.driveData.profile_designation.length ? this.driveData.profile_designation.map((designation: any) => ({ label: designation, value: designation })) : []
    this.fetchCampusDetail();

    if (!this.onlyPlaced) {
      this.getCountOfEveryStage();
      this.changeInfo({ label: 'Eligible Students', value: 'eligible', isActive: true, }, true)
    }
    else {
      this.changeInfo({ label: 'Placed', value: 'placed', isActive: true, }, true);
      const offerLetterColumn = { field: 'offer_letter', header: 'Offer Letter', freezable: false, sortable: true, tableDefault: true, width: '11vw', type: 'link' };
      const confirmationLetterColumn = { field: 'confirmation_letter', header: 'Confirmation Letter', freezable: false, sortable: true, tableDefault: true, width: '11vw', type: 'link' };
      this.columns.splice(2, 0, offerLetterColumn);
      this.columns.splice(3, 0, confirmationLetterColumn);
    }
  }

  needToShowMultipleProfileSelection() {
    return this.driveData.selectMultipleProfiles && (this.moveStudentsActions.to_stage === 'optIn' || ((this.activeTab != 'optIn' && this.activeTab != 'waiting') && this.moveStudentsActions.to_stage === 'placed') || ((this.activeTab != 'optIn' && this.activeTab != 'placed') && this.moveStudentsActions.to_stage === 'waiting'));
  }

  formatSelectedProfiles() {
    if (this.needToShowMultipleProfileSelection()) {
      if (this.driveData?.['preference_count'] >= 1) {
        let max = parseInt(this.driveData['preference_count'])
        this.selectedProfileForm = new FormControl(this.driveData.selected_profiles ?? [], [Validators.required, this.maxArrayLength(max)])
      }
      else {
        this.selectedProfileForm = new FormControl('', Validators.required)
      }
    }
  }

  maxArrayLength(max: number): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (!control.value) {
        return null;
      }
      return control.value.length > max ? { 'must': { requiredLength: max } } : null;
    };
  }

  clearSelectedFilters() {
    for (let key in this.selectedFilters) {
      if (this.selectedFilters.hasOwnProperty(key)) {
        this.selectedFilters[key] = [];
      }
    }
  }

  async changeInfo(info: iconsList, skip?: boolean) {
    if (info.value === this.activeTab && !skip) return;
    this.tableComponent?.resetTable();

    this.clearSelectedFilters()
    this.roundsOptions.forEach((item: any) => {
      item.isActive = (item.value === info.value)
      if (item.value === info.value) {
        this.activeTab = item.value;
      }
    });

    this.pushStudentStatusOptions()
    this.actionToSelect = [];
    this.columns = this.allColumns.map((col: any) => col);

    if (this.onlyPlaced) {
      this.actionToSelect.push(
        {
          item_name: 'Download', item_type: 'dropdown', options: [{ label: 'Offer Letter', value: 'downloadOfferLetter' }, { label: 'Confirmation Letter', value: 'downloadConfirmationLetter' }]
        },
        {
          item_name: 'Upload', item_type: 'dropdown', options: [{ label: 'Offer Letter', value: 'uploadOfferLetter' }, { label: 'Confirmation Letter', value: 'uploadConfirmationLetter' }]
        },
        {
          item_name: 'Remove', item_type: 'dropdown', options: [{ label: 'Offer Letter', value: 'removeOfferLetter' }, { label: 'Confirmation Letter', value: 'removeConfirmationLetter' }]
        },
      )

      this.configurePlacedTable();
    }
    else {

      if (this.activeTab === 'eligible') {
        this.actionToSelect.push(
          {
            item_name: `${this.opt_in_label}`, type: 'optIn', item_type: 'button',
          },
          {
            item_name: `${this.opt_out_label}`, type: 'optOut', item_type: 'button'
          },
          {
            item_name: 'Waiting', type: 'waiting', item_type: 'button'
          },
          {
            item_name: 'Placed', type: 'placed', item_type: 'button'
          },
          {
            item_name: 'Remove', type: 'removed', item_type: 'button'
          }
        );

        this.configureEligibleTable();
      };

      if (this.activeTab === 'optIn') {
        this.actionToSelect.push(
          {
            item_name: 'Eligible', type: 'eligible', item_type: 'button'
          },
          {
            item_name: `${this.opt_out_label}`, type: 'optOut', item_type: 'button'
          },
          {
            item_name: 'Waiting', type: 'waiting', item_type: 'button'
          },
          {
            item_name: 'Placed', type: 'placed', item_type: 'button'
          }
        )

        this.configureOptInTable();
      };

      if (this.activeTab === 'optOut') {
        this.actionToSelect.push(
          {
            item_name: 'Eligible', type: 'eligible', item_type: 'button'
          },
          {
            item_name: `${this.opt_in_label}`, type: 'optIn', item_type: 'button',
          },
          {
            item_name: 'Waiting', type: 'waiting', item_type: 'button'
          },
          {
            item_name: 'Placed', type: 'placed', item_type: 'button'
          }
        )

        this.configureOptOutTable();
      };

      if(this.activeTab === 'waiting') {
        this.actionToSelect.push(
          {
            item_name: 'Eligible', type: 'eligible', item_type: 'button'
          },
          {
            item_name: `${this.opt_out_label}`, type: 'optOut', item_type: 'button'
          },
          {
            item_name: 'Placed', type: 'placed', item_type: 'button'
          }
        )
      };

      if (this.activeTab === 'placed') {
        this.actionToSelect.push(
          {
            item_name: 'Eligible', type: 'eligible', item_type: 'button'
          },
          {
            item_name: `${this.opt_out_label}`, type: 'optOut', item_type: 'button'
          },
          {
            item_name: 'Waiting', type: 'waiting', item_type: 'button'
          }
        );

        this.configurePlacedTable();
      };

      if (this.activeTab === 'removed') {
        this.actionToSelect.push(
          {
            item_name: 'Eligible', type: 'eligible', item_type: 'button'
          },
          {
            item_name: `${this.opt_in_label}`, type: 'optIn', item_type: 'button',
          },
          {
            item_name: `${this.opt_out_label}`, type: 'optOut', item_type: 'button'
          },
          {
            item_name: 'Waiting', type: 'waiting', item_type: 'button'
          },
          {
            item_name: 'Placed', type: 'placed', item_type: 'button'
          }
        )

        this.removeInterestedProfile();
      };

      if (this.activeTab === 'notEligible') {
        this.actionToSelect = [
          {
            item_name: 'Eligible', type: 'eligible', item_type: 'button'
          }
        ];

        this.removeInterestedProfile();
      };
    };


    this.clearAllFilters();
  };

  closesideBar(event: any) {
    this.sendemailslider = event;
    this.selectAllStudents = false;
    this.selectAllStudentCount = 0;
  }

  private configureOptInTable() {
    this.columns[0] = {
      field: 'manually_opt_action', header: '', freezable: true, sortable: false, tableDefault: true, width: '3vw', type: 'pen_icon'
    };

    this.columns.push({
      field: 'opt_action_timestamp', header: 'Timestamp', freezable: false, sortable: true, tableDefault: true, width: '14vw', type: 'dateTimeSeconds'
    });
  }

  private configureOptOutTable() {
    this.columns[0] = {
      field: 'manually_opt_action', header: '', freezable: true, sortable: false, tableDefault: true, width: '3vw', type: 'pen_icon'
    };

    this.removeInterestedProfile();

    this.columns.push({
      field: 'opt_action_timestamp', header: 'Timestamp', freezable: false, sortable: true, tableDefault: true, width: '14vw', type: 'dateTimeSeconds'
    });

    this.columns.push({
      field: 'opt_out_reason', header: 'Reason', freezable: false, sortable: true, tableDefault: true, width: '14vw'
    });
  };

  private configureEligibleTable() {
    this.columns[0] = {
      field: 'manually_added', header: '', freezable: true, sortable: false, tableDefault: true, width: '3vw', type: 'pen_icon'
    };

    this.removeInterestedProfile();
  };

  private configurePlacedTable() {
    this.columns = this.columns.filter((col: any) => col.field !== 'actions');

    this.columns.push(
      {
        field: 'offer_type', header: 'Offered Type', freezable: false, sortable: true, tableDefault: true, width: '12vw'
      },
      {
        field: 'profile', header: 'Offered Profile', freezable: false, sortable: true, tableDefault: true, width: '12vw'
      },
      {
        field: 'salary', header: `Offered ${this.salary_label}`, freezable: false, sortable: true, tableDefault: true, width: '12vw'
      },
      {
        field: 'stipend', header: 'Offered Stipend', freezable: false, sortable: true, tableDefault: true, width: '12vw'
      }
    );
  };

  private removeInterestedProfile() {
    this.columns.splice(this.columns.length - 1);
  };

  async pushStudentStatusOptions() {
    this.filterData[2].array = []
    if (this.activeTab === 'eligible') {
      this.filterData[2].array.push(
        { label: this.optin_past, value: 'optedIn' },
        { label: this.optout_past, value: 'optedOut' },
        { label: 'No Action', value: 'eligibleNoAction' },
        { label: 'Waiting List', value: 'waitingList' },
        { label: 'Placed', value: 'placed' },
        { label: 'Manually Added', value: 'manuallyAdded' }
      )
    }
    else if (this.activeTab === 'optIn') {
      this.filterData[2].array.push(
        { label: this.optin_past, value: 'optInNoAction' },
        { label: 'Waiting List', value: 'waitingList' },
        { label: 'Placed', value: 'placed' },
      )
    }
    else if (this.activeTab === 'notEligible') {
      this.filterData[2].array.push(
        { label: 'Not Eligible', value: 'notEligible' },
        { label: 'Not Approved', value: 'notApproved' },
        { label: 'Not Interested', value: 'notInterested' },
        { label: 'Drive Disabled', value: 'driveDisabled' }
      )
    }
  }

  async searchValueChange(searchValue: any) {
    this.searchValue = searchValue;
    this.selectedPage = 1;
    this.fetchStudentsInDrives()
  }

  searchChange(searchValue: string) {
    this.searchValue = searchValue;
    this.selectedPage = 1;
  }

  async setOfferTypeOpen() {
    this.sideBarPurpose = 'setOfferType'
    this.isLoading = true
    const offerType = await this.driveService.getDriveTypes()
    this.isLoading = false
    this.offerTypeProperty = [
      {
        property_name: 'Select Profile', property: 'dropdown', array: [], visible: false, label: 'label', value: 'label', error_key: 'Profile'
      },
      {
        property_name: 'Select Offer Type', property: 'dropdown', array: offerType, visible: true, label: 'job_type', value: 'job_type_id', error_key: 'Offer Type'
      },
      {
        property_name: 'Offered Stipend', key: 'stipend', property: 'input', visible: false, error_key: 'Stipend'
      },
      {
        property_name: `Offered ${this.salary_label}`, key: 'salary', property: 'input', visible: false, error_key: 'Salary'
      }
    ]
    if (this.driveData.profile_designation.length) {
      this.offerTypeProperty[0].array = this.profileOptions
      this.offerTypeProperty[0].visible = true
    }

    this.emailOrReg = this.selectedOptions.map(each => each.primary_email)

  }

  async tableAction(event: { type: string; student_data: any[]; selectAll: boolean; }) {
    const { type, student_data, selectAll } = event;

    if (type === 'setOfferType') {
      this.sideBarPurpose = 'setOfferType'
      this.visible = true
      this.isLoading = true
      const offerType = await this.driveService.getDriveTypes()
      this.isLoading = false
      this.offerTypeProperty = [
        {
          property_name: 'Select Profile', property: 'dropdown', array: [], visible: false, label: 'label', value: 'label', error_key: 'Profile'
        },
        {
          property_name: 'Select Offer Type', property: 'dropdown', array: offerType, visible: true, label: 'job_type', value: 'job_type_id', error_key: 'Offer Type'
        },
        {
          property_name: 'Offered Stipend', key: 'stipend', property: 'input', visible: false, error_key: 'Stipend'
        },
        {
          property_name: `Offered ${this.salary_label}`, key: 'salary', property: 'input', visible: false, error_key: 'Salary'
        }
      ]
      if (this.driveData.profile_designation.length) {
        this.offerTypeProperty[0].array = this.profileOptions
        this.offerTypeProperty[0].visible = true
      }

      this.emailOrReg = this.selectedOptions.map(each => each.primary_email)
    }

    if(type === 'sendEmail')
    {
      if(student_data.length || selectAll)
      {
        this.sendEmailSlider(student_data, selectAll)
      }
      else
      {
        this.messageService.add({
          severity: Severity.WARN,
          summary: Summary.WARNING,
          detail: Message.SELECTSTUDENTS,
        });
      }
    }

    if (type === 'eligible' || type === 'optIn' || type === 'optOut' || type === 'placed' || type === 'removed' || type == 'notEligible' || type === 'waiting') {

      this.moveStudentsActions = {
        active_tab: this.driveStudentStageDisplay[this.activeTab],
        selectAll,
        student_data,
        stage: this.driveStudentStageDisplay[type as InterViewProcessTabs],
        to_stage: type
      };

      this.formatSelectedProfiles();

      if(type === 'placed') {
        this.visible = true;
        this.setOfferTypeOpen();
        this.sideBarPurpose = 'setOfferType';
        return;
    }

      this.showMoveStudents = true;
    };

  }

  sendEmailSlider(student_data: any, selectAll: any) {
    this.sendemailslider = true;
    this.studentDataList = student_data;
    if(selectAll)
    {
      this.selectAllStudentCount = this.dataCount;
      this.selectAllStudents = selectAll;
      this.driveMailInfo = 
      {
        drive_id: this.driveData.drive_id,
        drive_stage: this.activeTab,
        sort_by: this.sort_by,
        sort_order: this.sort_order,
      }
    }
  }

  async downloadZipData(selectedUsers: any[], type: string) {
    let errorType = '';
    if (type === 'resume') {
      errorType = 'resumes';
    } else if (type === 'confirmation_letter') {
      errorType = 'confirmation letter';
    } else if (type === 'offer_letter') {
      errorType = 'offer letter';
    }
    const { drive_id, company_name, name } = this.driveData;

    const payload = {
      drive_id,
      drive_name: name,
      group_account_id: this.accountData.group_account_id,
      type: `${type}`,
      selectedUsers,
      company_name,
      filters: this.getSelectedFilters(),
      limit: this.dataCount,
      page: this.selectedPage,
      search: this.searchValue,
      user_id: this.userData.user_id,
      from_stage: this.activeTab,
      sort_by: this.sort_by,
      sort_order: this.sort_order,
      active_tab: this.activeTab
    };

    this.isLoading = true;
    const response = await this.roundsService.drivesStudentDataDownload(payload);
    this.isLoading = false;

    if (response.data) {
      return this.messageService.add({
        severity: Severity.SUCCESS,
        summary: Summary.DOWNLOADINITIATED,
        detail: Message.BACKGROUNDPROCESS
      });
    };

    return this.messageService.add({
      severity: Severity.ERROR,
      summary: Summary.ERROR,
      detail: `There were no ${errorType} available for download`
    });
  }

  async rowLengthChange(length: any) {
    this.rowsize = length;
    this.selectedPage = 1
    this.fetchStudentsInDrives()
  }

  async pageChange(selectedPage: any) {
    this.selectedPage = selectedPage
    this.fetchStudentsInDrives()
  }

  async handlePaste(event: ClipboardEvent) {
    const clipboardData: any = event.clipboardData;
    const pastedText = clipboardData.getData('text');
    this.emailOrReg = pastedText.split('\n').map((email: any) => email.trim()).filter((email: any) => email !== '');
  }

  addStudentsManually() {
    this.visible = true
  }

  categoryChange() {
    this.emailOrReg = []
    this.errorData = []
  }

  clearError() {
    this.errorData = [];
  }

  clearInputs() {
    this.errorData = []
    this.selectedCategory = 'Email'
    this.emailOrReg = []
    this.sendEmail = false;
  }

  preventInvalidCharacters(event: KeyboardEvent) {
    const invalidChars = ['-', 'e', 'E'];

    if (invalidChars.includes(event.key)) {
      event.preventDefault();
    }
  }

  validateInput(event: Event, row: any) {
    const inputElement = event.target as HTMLInputElement;
    let value = parseInt(inputElement.value.trim(), 10);

    if (value > this.driveService.salaryLimit) {
      value = this.driveService.salaryLimit;
    } else if (value < 0) {
      value = 0;
    }

    inputElement.value = value.toString();
    row.values = value;
  };

  async fetchCampusDetail() {
    const response = await this.driveService.fetchCampusDetails(this.group_account_id);
    if (response) {
      this.campusDetails = response;
      this.updateFilterData();
    }
    else {
      this.messageService.add({
        severity: Severity.ERROR,
        summary: Summary.ERROR,
        detail: Message.ADMISSIONERROR,
      });
    }
  }

  private updateFilterData() {
    this.filterData[0].array = [];
    this.filterData[3].array = [];
    this.filterData[4].array = [];
    this.filteredDepartments = [];

    const uniqueItems = {
      campus: new Set<string>(),
      department: new Set<string>(),
      degree: new Set<string>()
    };

    const driveSelectedCampus = new Set(this.driveData.account_id);

    this.campusDetails.forEach((item) => {
      if (driveSelectedCampus.size === 0 || driveSelectedCampus.has(item.account_id)) {
        this.addUniqueItem(uniqueItems.campus, item.account_id, 0, item.full_name);
        this.addUniqueItem(uniqueItems.department, item.department_id, 3, item.department);
        this.addUniqueDegree(item, uniqueItems.degree);
      }
    });

    this.sortFilter([0, 3, 4]);
  };

  addUniqueItem(set: Set<string>, id: string, index: number, label: string) {
    if (!set.has(id)) {
      set.add(id);
      this.filterData[index].array.push({ label, value: id });
    }
  };

  private addUniqueDegree(item: any, degreeSet: Set<string>) {
    const key = `${item.degree_id}-${item.programme_id}`;
    if (!degreeSet.has(key)) {
      degreeSet.add(key);
      this.filterData[4].array.push({
        label: `${item.degree_name} - ${item.specialization}`,
        value: { degree_id: item.degree_id, programme_id: item.programme_id }
      });
    }
  };

  private sortFilter(sortItems: number[]) {
    sortItems.forEach((each) => {
      this.filterData[each].array.sort((a: any, b: any) => a.label.localeCompare(b.label));
    })
  };

  async fetchStudentsInDrives() {
    const payload = {
      limit: this.rowsize,
      page: this.selectedPage,
      search: this.searchValue,
      filters: this.getSelectedFilters(),
      sort_by: this.sort_by,
      sort_order: this.sort_order,
      drive_id: this.driveData.drive_id,
      group_account_id: this.accountData.group_account_id,
      user_id: this.userData.user_id,
      drive_stage: this.activeTab
    };
    this.tableLoader = true;
    this.dataList = []
    const response = await this.roundsService.fetchForInterviewProcess(payload)
    this.tableLoader = false;
    if (response.success && response.data.data.length) {
      this.tableComponent.resetSelected()
      this.dataList = response.data.data
      this.dataCount = response.data.total_count
      this.totalPage = Math.ceil(this.dataCount / this.rowsize);
    }
    else if (response.success && response.data.data.length <= 0) {
      this.dataCount = 0
      this.totalPage = 1
    }
    else {
      this.messageService.add({
        severity: Severity.ERROR,
        summary: Summary.ERROR,
        detail: Message.FETCHSTUDENTSERR,
      });
    }
  }

  async getCountOfEveryStage() {
    const payload: any = {}
    payload.drive_id = this.driveData.drive_id
    payload.group_account_id = this.accountData.group_account_id
    this.loader = true
    const response = await this.roundsService.getCountOfEveryStages(payload)
    this.loader = false
    if (response.success) {
      const counts = response.data[0];
      this.roundsOptions.forEach(option => {
        option.count = parseInt(counts[option.value + '_count']) || 0;
      });
    }
  }

  getFirstFiveFilters(): any[] {
    if (this.activeTab === 'optOut' || this.activeTab === 'removed' || this.activeTab === 'placed' || this.activeTab === 'waiting') {
      return this.filterData.filter((item: any) => item.key !== 'studentStatus');
    }
    else {
      return this.filterData.slice(0, 3);
    }
  }

  remainingFilters(): any[] {
    if (this.activeTab === 'optOut' || this.activeTab === 'removed' || this.activeTab === 'placed' || this.activeTab === 'waiting') {
      return [];
    };

    return this.filterData.slice(3);
  }

  handleFilterChange(selectedFilter: string, event: any) {
    if (selectedFilter === 'campus') {
      this.filterData[3].array = [];
      this.filterData[4].array = [];
      this.selectedFilters['degreeSpec'] = [];
      this.selectedFilters['department'] = [];
      this.handleCampusFilter(event.value);
    } else if (selectedFilter === 'department') {
      this.filterData[4].array = [];
      this.selectedFilters['degreeSpec'] = [];
      this.handleDepartmentFilter(event.value);
    }
  };

  private handleCampusFilter(selectedCampuses: string[]) {
    const eventValueSet = new Set(selectedCampuses);

    this.filteredDepartments = this.campusDetails.filter(element =>
      eventValueSet.has(element.account_id)
    );
    const uniqueItems = {
      department: new Set<string>(),
      degree: new Set<string>()
    };

    const selected = this.filteredDepartments.length ? this.filteredDepartments : this.campusDetails;

    selected.forEach((element: any) => {
      this.addUniqueItem(uniqueItems.department, element.department_id, 3, element.department);
      this.addUniqueDegree(element, uniqueItems.degree);
    });

    this.sortFilter([3, 4]);
  };

  private handleDepartmentFilter(selectedDepartments: any[]) {
    const eventValueSet = new Set(selectedDepartments);

    const degreeSet = new Set<string>();
    const selected = this.filteredDepartments.length ? this.filteredDepartments : this.campusDetails;

    selected.forEach((element: any) => {
      if (eventValueSet.size == 0 || eventValueSet.has(element.department_id)) {
        this.addUniqueDegree(element, degreeSet);
      }
    });

    this.sortFilter([4]);
  };

  private getSelectedFilters() {
    const { campus: selectedCampusIds, department: selectedDepartmentIds, degreeSpec: selectedDegreeSpec } = this.selectedFilters;

    const selectedFilters = { ...this.selectedFilters };

    const selectedCampuses = new Set(selectedCampusIds);
    const selectedDepartments = new Set(selectedDepartmentIds);
    const selectedDegrees = new Set(selectedDegreeSpec.map((each: any) => `${each.degree_id}-${each.programme_id}`));

    const isCampusSelected = selectedCampuses.size > 0;
    const isDepartmentSelected = selectedDepartments.size > 0;
    selectedFilters.department = [];
    selectedFilters.degreeSpec = [];

    this.campusDetails.forEach(each => {
      const campusMatch = selectedCampuses.has(each.account_id);
      const departmentMatch = selectedDepartments.has(each.department_id);
      const degreeMatch = selectedDegrees.has(`${each.degree_id}-${each.programme_id}`);

      // Add to department if campus or department matches
      if ((!isCampusSelected && departmentMatch) || (campusMatch && departmentMatch)) {
        selectedFilters.department.push({
          account_id: each.account_id,
          department_id: each.department_id
        });
      }

      // Add to degreeSpec if the corresponding conditions match
      if (
        (isCampusSelected && isDepartmentSelected && campusMatch && departmentMatch && degreeMatch) ||
        (!isCampusSelected && isDepartmentSelected && departmentMatch && degreeMatch) ||
        (isCampusSelected && !isDepartmentSelected && campusMatch && degreeMatch) ||
        (!isCampusSelected && !isDepartmentSelected && degreeMatch)
      ) {
        selectedFilters.degreeSpec.push({
          account_id: each.account_id,
          department_id: each.department_id,
          degree_id: each.degree_id,
          programme_id: each.programme_id
        });
      }
    });

    return selectedFilters;
  };

  onCloseMoreFilters() {
    this.filtersCount = 0;
    if (this.selectedFilters.degreeSpec.length) {
      this.filtersCount++;
    }
    if (this.selectedFilters.department.length) {
      this.filtersCount++;
    }
  }

  onClickMoreFilters() {
    this.sideBarPurpose = 'moreFilters'
    this.visible = true
  }

  async clearAllFilters() {
    this.clearSelectedFilters()
    this.filtersCount = 0;
    this.filterData[3].array = [];
    this.filterData[4].array = [];

    this.selectedFilters['campus'] = [];
    this.selectedFilters['department'] = [];
    this.selectedFilters['degreeSpec'] = [];
    this.selectedFilters['studentStatus'] = [];
    this.selectedFilters['passOutYear'] = [];
    this.searchValue = '';
    this.selectedPage = 1;
    if (this.tableComponent) {
      this.tableComponent.searchValue = '';
      this.tableComponent.currentPage = 1;
      this.dataList = [];
    }

    this.filteredDepartments = [];
    this.updateFilterData();
    this.fetchStudentsInDrives();
  };

  async applyFilter() {
    this.fetchStudentsInDrives()
  }

  toggleOverlayPanel() {
    this.opVisible = !this.opVisible; // Toggle the value of opVisible
  }

  hideOverlay() {
    this.opVisible = false
  }

  tableOptionsEvent(event: any) {
    this.clicked = false
    if (event === 'addStudents' || event === 'removeStudents') {
      this.sideBarPurpose = event;
      this.visible = true
    };
  };

  async sortChange(event: any) {
    this.sort_by = event.field;
    this.sort_order = event.order;
    await this.fetchStudentsInDrives();
  }

  async addStudentManually(purpose: string) {
    if (this.driveStatus === PatDriveStatus.completed) {
      return this.messageService.add({
        severity: Severity.ERROR,
        summary: Summary.ERROR,
        detail: Message.DRIVECOMPLETE
      });
    };

    const { drive_id, name, drive_number } = this.driveData;

    this.clicked = true
    if(!this.emailOrReg.length) {
      this.clicked = false
      return this.messageService.add({
        severity: Severity.ERROR,
        summary: Summary.ERROR,
        detail: Message.ENTEREMAILREG,
      });
    };

    const payload: any = {
      group_account_id: this.group_account_id,
      drive_id,
      drive_number,
      drive_name: name,
      done_by: this.userData.primary_email,
      send_email: this.sendEmail,
      purpose: purpose === 'addStudents' ? 'Add' : 'Remove',
      email_ids: this.selectedCategory === 'Email' ? this.emailOrReg : [],
      reg_ids: this.selectedCategory === 'Registration Number' ? this.emailOrReg : [],
    };
    
    this.isLoading = true;
    const response = await this.roundsService.manuallyAddRemoveStudents(payload)
    this.isLoading = false;

    if(!response.success) {
      this.messageService.add({
        severity: Severity.ERROR,
        summary: Summary.ERROR,
        detail: Message.ERROROCCURED,
      });
      return;
    };

    const { error_data } = response.data;

    if (error_data?.length) {
      this.errorData = error_data
      return this.messageService.add({
        severity: Severity.ERROR,
        summary: Summary.VALIDATIONERROR,
        detail: Message.CHECKERRDATA,
      });
    };

    this.sideBarPurpose = '';
    this.visible = false;
    this.sendEmail = false;
    this.messageService.add({
      severity: Severity.SUCCESS,
      summary: Summary.SUCCESS,
      detail: `Students manually ${purpose === 'addStudents' ? 'added' : 'removed'}`,
    });
    this.clearInputs();
    this.fetchStudentsInDrives();
    this.getCountOfEveryStage();
  };

  getStatusClass(driveStatus: string): any {
    return {
      'eligible': driveStatus.includes('Eligible'),
      'opted-in': driveStatus.includes('Opted In') || driveStatus.includes('Applied'),
      'opted-out': driveStatus.includes('Opted Out') || driveStatus.includes('Declined'),
      'no-action-only': driveStatus.includes('No Action'),
      'removed': driveStatus.includes('Removed'),
      'rejected': driveStatus.includes('Rejected'),
      'placed': driveStatus.includes('Placed'),
      'waiting-list': driveStatus.includes('Waiting List') || driveStatus.includes('Not Eligible'),
      'selected-std': driveStatus.includes('Selected'),
      'not-eligible': driveStatus.includes('Not Approved') || driveStatus.includes('Not Interested') || driveStatus.includes('Drive Disabled')
    };
  };

  clearValues() {
    this.onCloseSetOfferType()
    this.visible = false
    this.moveStudentsActions = {
      to_stage: null,
      student_data: [],
      selectAll: false,
      active_tab: 'Eligible',
      stage: 'Eligible'
    };
    this.showMoveStudents = false;
    this.sendEmail = false;
    this.reasonForMovingRound = '';
    this.priorityArray = [];
    this.setOfferTypePayload = {};
  }

  onFileSelected(event: any) {
    if(!event.target.files.length) {
      return;
    }
    const selectedFile = event.target.files[0];

    // restrict file length 5MB
    if (selectedFile.size > 5242880) {
      this.messageService.add({
        severity: Severity.ERROR,
        summary: Summary.ERROR,
        detail: Message.FILESIZE5MBLIMIT
      });
      return;
    };

    // file type only pdf
    if (selectedFile.type !== 'application/pdf') {
      this.messageService.add({
        severity: Severity.ERROR,
        summary: Summary.ERROR,
        detail: Message.FILETYPEPDF
      });
      return;
    };

    this.fileUploadEvent = event;
    this.pdfSrc = '';
    this.sideBarPurpose = 'previewOfferOrConfirmation';
    this.visible = true;

    const reader = new FileReader();
    reader.onload = (e: any) => {
      const blob = new Blob([e.target.result], { type: selectedFile.type });
      this.pdfSrc = URL.createObjectURL(blob);
    };
    reader.readAsArrayBuffer(selectedFile);
  }

  loadingInitiate(event: boolean){
    this.isLoading = event
  }

  cancelUpload() {
    this.pdfSrc = '';
    this.fileUploadEvent.target.value = null;
    this.sideBarPurpose = '';
    this.visible = false;
    this.fileBase64 = '';
    this.filePath = '';
    this.uploadFiles = false;
    this.emailOrReg = [];
  }

  async uploadLetter() {
    const payload: any = {
      drive_id: this.driveData.drive_id,
      user_id: this.selectedOptions[0].user_id
    }
    if (this.offerLetterOrConfirmation === 'Offer letter') {
      payload.offer_letter = this.bucketData.fileName
    }
    else if (this.offerLetterOrConfirmation === 'Confirmation letter') {
      payload.confirmation_letter = this.bucketData.fileName
    }
    const type = this.offerLetterOrConfirmation === "Offer letter" ? 'Offer letter' : 'Confirmation letter'
    const response = await this.roundsService.uploadOfferOrConfirmation(payload)
    if (response?.success) {
      this.sideBarPurpose = ''
      return this.messageService.add({
        severity: Severity.SUCCESS,
        summary: Summary.SUCCESS,
        detail: `${type} uploaded successfully`,
      });
    }
    else {
      this.visible = true
      return this.messageService.add({
        severity: Severity.ERROR,
        summary: Summary.ERROR,
        detail: `Error in uploading ${type}`,
      });
    }
  }

  async removeOfferOrConfirm(selectAll: any, studentData: any, purpose: any) {
    try {
      const user_ids = !selectAll ? studentData.map((each: any) => each.user_id) : [];
      const payload = {
        drive_id: this.driveData.drive_id,
        user_ids: user_ids,
        purpose: purpose
      };
      this.isLoading = true;
      const response = await this.roundsService.removeOfferOrConfirmation(payload);

      if (response?.success && response.data) {
        this.tableComponent.resetTable();
        this.fetchStudentsInDrives();
        this.messageService.add({
          severity: Severity.SUCCESS,
          summary: Summary.SUCCESS,
          detail: `${purpose} removed successfully`,
        });
        return
      }

      throw new Error('Error in removing offer letter');
    } catch (error) {
      this.messageService.add({
        severity: Severity.ERROR,
        summary: Summary.ERROR,
        detail: `Error in removing ${purpose}`
      });
    }
    finally {
      this.isLoading = false;
    }
  }

  async confirmUpload() {
    this.visible = false
    this.isLoading = true
    const event = this.fileUploadEvent
    await this.handleUpload(event, 'resume')
  }

  async handleUpload(event: any, type: string) {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = async () => {
      await this.fileUpload(file, type);
    };
  }

  async fileUpload(file: any, type: any) {
    this.uploadingFile = true;
    let fileName = file.name.split('.');
    let fileExtension = fileName.pop();
    fileName = `${this.selectedOptions[0].primary_email}.${fileExtension}`;
    const purpose = this.offerLetterOrConfirmation === 'Offer letter' ? 'offer_letter' : 'confirmation_letter'
    this.filePath = `${this.group_account_id}/offer-confirmation/${this.driveData.drive_id}/${purpose}/${fileName}`;
    this.bucketData = {
      bucketName: this.globalService.studentBucket,
      fileName: this.filePath,
      type: file.type,
    };
    const blobData: any = file;

    const signedUrl = await this.globalService.getSignedUploadUrl(this.bucketData);

    if (signedUrl?.data) {
      const json = signedUrl;
      await this.fileUploadService.uploadUsingSignedUrl(
        json.data.response,
        blobData
      );
      // this.fileBase64 = `${this.}/${this.globalService.bucketName}/${this.bucketData.file_name}`;
      this.uploadingFile = false;
    }
    await this.uploadLetter()
    this.isLoading = false
    this.tableComponent.resetTable();
    await this.fetchStudentsInDrives();
  }

  async driveMoveAction(event: { type: string; value: string; student_data: any[]; selectAll: boolean; }) {
    const { value, student_data, selectAll } = event;
    this.offerLetterOrConfirmation = '';
    const selectedUsers = selectAll ? [] : student_data.map((each: any) => each.user_id);

    if (value === 'uploadOfferLetter') {
      this.offerLetterOrConfirmation = 'Offer letter'
      if (this.selectedOptions.length > 1) {
        return this.messageService.add({
          severity: Severity.ERROR,
          summary: Summary.ERROR,
          detail: `Select only one student to upload the ${this.offerLetterOrConfirmation}`
        });
      }
      this.fileInputRef?.nativeElement.click();
    }
    else if (value === 'uploadConfirmationLetter') {
      this.offerLetterOrConfirmation = 'Confirmation letter'
      if (this.selectedOptions.length > 1) {
        return this.messageService.add({
          severity: Severity.ERROR,
          summary: Summary.ERROR,
          detail: `Select only one student to upload the ${this.offerLetterOrConfirmation}`
        });
      }
      this.fileInputRef.nativeElement.click();
    }
    else if (value === 'removeOfferLetter') {
      this.confirmationService.confirm({
        message: 'Are you sure that you want to remove the offer letter?',
        header: 'Confirmation',
        icon: 'pi pi-exclamation-triangle',
        accept: async () => {
          await this.removeOfferOrConfirm(selectAll, student_data, 'Offer letter')
        },
        reject: () => {
        }
      });
    }
    else if (value === 'removeConfirmationLetter') {
      this.confirmationService.confirm({
        message: 'Are you sure that you want to remove the confirmation letter?',
        header: 'Confirmation',
        icon: 'pi pi-exclamation-triangle',
        accept: async () => {
          await this.removeOfferOrConfirm(selectAll, student_data, 'Confirmation letter')
        },
        reject: () => {
        }
      });
    }
    else if (value === 'downloadOfferLetter') {
      await this.downloadZipData(selectedUsers, 'offer_letter',)
    }
    else if (value === 'downloadConfirmationLetter') {
      await this.downloadZipData(selectedUsers, 'confirmation_letter')
    }


    // handle other events here
    if (value === 'Send') {
      return;
    }
  }

  // handle move to rounds and mark as events here
  async driveMoveToRounds() {
    if (this.driveStatus === PatDriveStatus.hold) {
      return this.messageService.add({
        severity: Severity.ERROR,
        summary: Summary.ERROR,
        detail: Message.DRIVEHOLD
      });
    }

    if (this.driveStatus === PatDriveStatus.completed) {
      return this.messageService.add({
        severity: Severity.ERROR,
        summary: Summary.ERROR,
        detail: Message.MOVESTUDENTS
      });
    }

    const bool = this.needToShowMultipleProfileSelection();

    if (bool && this.selectedProfileForm.invalid) {
      return this.messageService.add({
        severity: Severity.ERROR,
        summary: Summary.ERROR,
        detail: Message.MOVESTUDENTSPROFILE
      });
    };


    const {
      drive_id, name, company_category, company_id, drive_number, drive_objective, company_name
    } = this.driveData;

    const { to_stage, student_data, selectAll } = this.moveStudentsActions;

    const payload: MoveRoundsType = {
      drive_id,
      drive_number,
      company_name,
      drive_name: name,
      from_stage: DriveStudentStageMove[this.activeTab],
      student_data,
      group_account_id: this.group_account_id,
      done_by: this.userData.primary_email,
      selectAll,
      reason: this.reasonForMovingRound,
      send_email: this.sendEmail,
      filters: this.getSelectedFilters(),
      limit: this.dataCount,
      page: this.selectedPage,
      search: this.searchValue,
      company_category,
      company_id,
      drive_objective,
      sort_by: this.sort_by,
      sort_order: this.sort_order,
      to_stage: DriveStudentStageMove[to_stage as InterViewProcessTabs],
      active_tab: this.activeTab,
      ...(bool ? { selected_profiles: this.priorityArray.length ? this.priorityArray : [this.selectedProfileForm.value] } : {}),
      ...(Object.keys(this.setOfferTypePayload).length ? { setOfferTypePayload: this.setOfferTypePayload } : {})
    };

    this.isLoading = true;
    let response = await this.roundsService.driveMoveToRounds(payload);
    this.isLoading = false;
    this.clearValues();

    const { success, message, all } = response.data;

    if (all) {
      return this.messageService.add({
        severity: Severity.ERROR,
        summary: Summary.ERROR,
        detail: message
      });
    }

    const severity = success ? Severity.SUCCESS : Severity.WARN;
    const summary = success ? Summary.SUCCESS : Summary.PARTIAL_MOVED;

    this.tableComponent.resetTable();
    this.fetchStudentsInDrives();
    this.getCountOfEveryStage();

    return this.messageService.add({
      severity,
      summary,
      detail: message
    });

  };

  onCloseSetOfferType() {
    this.clicked = false;
    this.sendEmail = false;
    this.emailOrReg = [];
    if (this.offerTypeProperty?.length) {
      this.offerTypeProperty.forEach((element: any) => {
        element.value = '';
      });
    }
    this.offerTypeFor['salary'] = false;
    this.offerTypeFor['stipend'] = false;
    this.visible = false;
    this.sideBarPurpose = '';
  }

  handleCheckboxStateChange(data: any) {
    this.selectedOptions = data.selectedOptions;
  }

  async setOfferType() {
    this.clicked = true;

    if (this.emailOrReg.length === 0) {
      return this.messageService.add({
        severity: Severity.ERROR,
        summary: Summary.ERROR,
        detail: Message.ATLEASTONESTUDENT,
      });
    };

    for (const each of this.offerTypeProperty) {
      if (each.key !== 'salary' && each.key !== 'stipend' && !each.values) {
        return this.messageService.add({
          severity: Severity.ERROR,
          summary: Summary.ERROR,
          detail: Message.SELECTALLFIELD,
        });
      }
    };

    if (!this.offerTypeFor.salary && !this.offerTypeFor.stipend) {
      return this.messageService.add({
        severity: Severity.ERROR,
        summary: Summary.ERROR,
        detail: Message.ENTERSALARY,
      });
    };

    if (this.offerTypeFor.salary && !this.offerTypeProperty[3].values) {
      return this.messageService.add({
        severity: Severity.ERROR,
        summary: Summary.ERROR,
        detail: Message.OFFEREDSALARY,
      });
    }

    if (this.offerTypeFor.stipend && !this.offerTypeProperty[2].values) {
      return this.messageService.add({
        severity: Severity.ERROR,
        summary: Summary.ERROR,
        detail: Message.OFFEREDSTIPEND,
      });
    }

    this.setOfferTypePayload = {
      drive_id: this.driveData.drive_id,
      profile: this.offerTypeProperty[0].values,
      offer_type: this.offerTypeProperty[1].array.find((each: any) => each.job_type_id === this.offerTypeProperty[1].values).job_type,
      offer_id: this.offerTypeProperty[1].values,
      stipend: this.offerTypeProperty[2].values ? this.offerTypeProperty[2].values : null,
      salary: this.offerTypeProperty[3].values ? this.offerTypeProperty[3].values : null
    };
    this.showMoveStudents = true

    // this.isLoading = true;
    // const response = await this.roundsService.setOfferTypes(payload);
    // this.isLoading = false;
    // if(response.success){
    //   const { all, partial, success } = response.data;
    //   this.tableComponent.resetTable();
    //   this.fetchStudentsInDrives();
    //   this.onCloseSetOfferType();

    //   if(all) {
    //     return this.messageService.add({
    //       severity: Severity.ERROR,
    //       summary: Summary.ERROR,
    //       detail: Message.NOEMAILS,
    //     });
    //   };

    //   if(partial) {
    //     return this.messageService.add({
    //       severity: Severity.WARN,
    //       summary: Summary.SUCCESS,
    //       detail: Message.STUDENTOFFERTYPE,
    //     });
    //   };

    //   if(success) {
    //     return this.messageService.add({
    //       severity: Severity.SUCCESS,
    //       summary: Summary.SUCCESS,
    //       detail: Message.OFFERTYPESET,
    //     });
    //   }
    // }
    // else{
    //   this.onCloseSetOfferType();
    //   this.messageService.add({
    //     severity: Severity.ERROR,
    //     summary: Summary.ERROR,
    //     detail: Message.OFFERSETTINGERR,
    //   });
    // }
  }

  salarySet() {
    this.offerTypeProperty[2].visible = this.offerTypeFor.stipend;
    this.offerTypeProperty[3].visible = this.offerTypeFor.salary;
  }

  async onClickFile(link: any) {
    const signedUrl = await this.globalService.getSignedUrl({
      bucketName: this.globalService.studentBucket,
      fileName: link,
    })
    link = signedUrl.data.response;
    window.open(link, '_blank')
  }

  redirectResume(rowData: any) {
    const { user_id } = rowData;
    const prefix: string = window.location.origin;
    const url = `${prefix}/student-resume?u_id=${user_id}&d_id=${this.driveData.drive_id}`
    window.open(url, '_blank');
  }

  async hideDownloadBar() {
    this.showDownData = false;

    this.downloadCategories.forEach((element: any) => {
      element.isSelectedAll = false;
      element.data = []
    });
    this.downloadColumnAs = 'excel';
  };

  async openDownloadDataSideBar() {
    this.showDownData = true;
    this.isLoading = true;
    const payload = {
      group_account_id: this.group_account_id,
      account_id: this.driveData.account_id,
      pass_out_year: this.driveData.passed_out_year,
    };

    let response = await this.globalService.fetchMappedFieldsBulk(payload);

    if (!response.success && !response.data) {
      this.isLoading = false;
      return this.messageService.add({
        severity: Severity.WARN,
        summary: Summary.EDITFIELDS,
        detail: Message.FIELDMAP,
      });
    }

    const uniqueFields = new Set();

    for (const eachCampus of response.data) {
      const { academic_info, basic_info, additional_info } = eachCampus;

      basic_info.forEach((element: any) => {
        if (element.attribute_name === 'Profile Picture' || element.attribute_name === 'Email' || uniqueFields.has(element.attribute_id)) {
          return;
        }
        this.downloadCategories[0].data.push({
          label: element.attribute_label,
          value: element.attribute_name,
          active: false
        });
        uniqueFields.add(element.attribute_id);
      });


      academic_info.forEach((element: any) => {
        if (uniqueFields.has(element.attribute_id)) return;
        this.downloadCategories[1].data.push({
          label: element.attribute_label,
          value: element.attribute_name,
          active: false
        });
        uniqueFields.add(element.attribute_id);
      });

      additional_info.forEach((element: any) => {
        if (element.field_type === "Terms & Conditions" || uniqueFields.has(element.attribute_id)) return;
        this.downloadCategories[2].data.push({
          label: element.attribute_label,
          value: element.attribute_name,
          type: element.field_type,
          active: false
        });
        uniqueFields.add(element.attribute_id);
      });
    }

    this.downloadCategories[3].data.push({
        label: 'Resume', 
        value: 'resume', 
        type: 'resume', 
        active: false
    });

    this.driveData.optionalFormData.forEach((element: any) => {
      if (element.fieldType === "termscondition") return;
      this.downloadCategories[4].data.push({
        label: element.question,
        value: element.id,
        type: element.fieldType,
        active: false
      });
    });

    const otherFields = ['Campus', 'Pass Out Year', 'Admission Year', 'Department', 'Degree And Specialization', 'Manually Added', 'Timestamp', 'Interested Profile', 'Offer Type', `Offered ${this.salary_label}`, 'Offered Stipend', 'Resume Link', 'Internship Count', 'Internship Companies', 'Internship Category', 'Internship Offer Type', `Highest ${this.salary_label} Internship`, 'Highest Stipend Internship', 'Placed Count', 'Placed Companies', 'Placed Category', 'Placed Offer Type', `Highest ${this.salary_label} Placed`, 'Highest Stipend Placed'];

    if (this.activeTab == 'optOut') {
      otherFields.push('Opted-Out Reason');
    }

    const customAcademicOrder = ['10th Mark', '12th Mark', 'Diploma Mark', 'UG Mark', 'PG Mark', 'Current Backlogs', 'Backlog History', 'Registration Number', 'Interest for Placement'];
    const customBasicOrder = ['First Name', 'Last Name', 'Gender', 'Email', 'Mobile Number', 'Profile Picture', 'Date of Birth'];

    this.downloadCategories[0].data.sort((a: any, b: any) => customBasicOrder.indexOf(a.value) - customBasicOrder.indexOf(b.value));
    this.downloadCategories[1].data.sort((a: any, b: any) => customAcademicOrder.indexOf(a.value) - customAcademicOrder.indexOf(b.value));

    otherFields.forEach((element: any) => {
      this.downloadCategories[1].data.push({
        label: element,
        value: element,
        active: false
      });
    });
    this.isLoading = false;
  }

  selectAllDownloadData(index: number) {
    this.downloadCategories[index].data.forEach((element: any) => {
      element.active = this.downloadCategories[index].isSelectedAll;
    });
  }

  onDropDownChange(index: number) {
    this.downloadCategories[index].isSelectedAll = this.downloadCategories[index].data.every((element: any) => element.active);
  }

  onProfileChange(event: any) {
    if (!this.selectedProfileForm?.valid) {
      this.priorityArray = [];
    }
    this.priorityArray = [];
    if (this.selectedProfileForm) {
      this.priorityArray = [...this.selectedProfileForm.value];
    }
  }

  drop($event: CdkDragDrop<any, any, any>) {
    moveItemInArray(this.priorityArray, $event.previousIndex, $event.currentIndex);
  }

  async downloadData() {
    if (!this.dataList.length) return this.messageService.add({ severity: Severity.ERROR, summary: Summary.ERROR, detail: Message.NOSTUDENTDATA });

    const basicAndAcademicFields: any = [];

    this.downloadCategories[0].data.forEach((element: any) => {
      if (element.active) basicAndAcademicFields.push(element.value);
    });

    this.downloadCategories[1].data.forEach((element: any) => {
      if (element.active) basicAndAcademicFields.push(element.value);
    });

    const additionalFields = this.downloadCategories[2].data
      .filter((element: any) => element.active);
    
    const resumeFields = this.downloadCategories[3].data[0].active ? ['resume'] : [];

    const optionalFields = this.downloadCategories[4].data
      .filter((element: any) => element.active);

    if (!basicAndAcademicFields.length && !additionalFields.length && !optionalFields.length && !resumeFields.length) {
      return this.messageService.add({ severity: Severity.ERROR, summary: Summary.ERROR, detail: Message.ATLEASTONEFIELD })
    }

    const selectedUsers = this.tableComponent.selectedAll ? [] : this.tableComponent.selectedOptions.map((each: any) => each.user_id);


    if(resumeFields.length) {
      this.downloadZipData(selectedUsers, 'drive_resume');
    };

    if (!basicAndAcademicFields.length && !additionalFields.length && !optionalFields.length) {
      this.hideDownloadBar();
      return;
    }
    const payload = {
      drive_name: this.driveData.name,
      company_name: this.driveData.company_name,
      drive_id: this.driveData.drive_id,
      group_account_id: this.group_account_id,
      filters: this.getSelectedFilters(),
      limit: this.dataCount,
      page: this.selectedPage,
      search: this.searchValue,
      from_stage: this.activeTab,
      basicAndAcademicFields,
      additionalFields,
      optionalFields,
      downloadAs: this.downloadColumnAs,
      user_id: this.userData.user_id,
      origin: window.location.origin,
      allFields: true,
      selectedUsers,
      sort_by: this.sort_by,
      sort_order: this.sort_order,
      active_tab: this.activeTab
    };

    this.hideDownloadBar();

    this.isLoading = true;
    const response = await this.driveService.driveDataDownload(payload);
    this.isLoading = false;

    if (response.success) {
      return this.messageService.add({
        severity: Severity.SUCCESS,
        summary: Summary.SUCCESS,
        detail: Message.BACKGROUNDPROCESS
      });
    }

    return this.messageService.add({
      severity: Severity.ERROR,
      summary: Summary.ERROR,
      detail: Message.DATADWNLDERR,
    });
  }
}
