//export every service file here
export * from './drive.service'
export * from './global.service'
export * from './http.service'
export * from './fileupload.service'
export * from './validation.service'
export * from './notification.service'
export * from './student.service'
export * from './settings.service'
export * from './eligiblity.service'
export * from './programme-offered.service'
export * from './header.service'