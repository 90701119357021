export enum Edrive {
    ALLOW = 'Allow to select the interested profiles',
    PROFILEBASED = 'Profile based Salary / Stipend',
    DEGREEBASED = 'Degree based Salary / Stipend',
    ADDNEWFIELD = 'Add New Salary/Stipend',
    REMOVEFIELD = 'Remove Salary/Stipend',
    SALARYBREAK = 'Enable Salary Breakup',
}

export type InterViewProcessTabs = 'eligible' | 'optIn' | 'optOut' | 'waiting' | 'placed' | 'removed' | 'notEligible';


export const DriveStudentStageMove = {
    eligible: 'Eligible',
    optIn: 'Opted_In',
    placed: 'Placed',
    optOut: 'Opted_Out',
    removed: 'Removed',
    notEligible: 'Not_Eligible',
    waiting: 'Waiting',
};


export const DriveStudentStageDisplay: { [key in InterViewProcessTabs]: string } = {
    'eligible': 'Eligible',
    'optIn': 'Opt In',
    'waiting': 'Waiting',
    'placed': 'Placed',
    'optOut': 'Opt Out',
    'removed': 'Removed',
    'notEligible': 'Not Eligible'
}

export enum PatDriveStatus {
    saved = 'Saved',
    upcoming= 'Upcoming',
    ongoing ='Ongoing',
    hold = 'Hold',
    completed = 'Completed',
    Active = 'Ongoing',
};

export const DriveTypes = {
    placement: 'Placement',
    internship: 'Internship',
    single: 'Single Profile Drive',
    multiple: 'Multiple Profile Drive',
}


export const MenuOptions = [
    { label: 'Placement', value: 'Placement', isActive: true },
    { label: 'Internship', value: 'Internship', isActive: false },
]
export const stdInterestedOptions = [
    { label: 'Single', value: 'single', isActive: true },
    { label: 'Multiple', value: 'multiple', isActive: false },
]
export const shortAnswerOptions = [
    { label: 'Text', value: 'text', isActive: true },
    { label: 'Number', value: 'number', isActive: false },
]

export const CreateOptions = [
    { label: 'Drive Details', value: 'details', isActive: true },
    { label: 'Eligibility', value: 'eligibility', isActive: false },
    { label: 'Interview Process', value: 'interviewProcess', isActive: false },
    { label: 'Offer & Confirmation', value: 'offerConfirmation', isActive: false },
    { label: 'Email Template', value: 'emailTemplate', isActive: false },
]

export const StdDetails = [
    { label: 'Student Info', value: 'info', isActive: true },
    // { label: 'Drive Info', value: 'driveInfo', isActive: false },
    { label: 'Audit Info', value: 'auditInfo', isActive: false },
]

export const CreateDriveOptions = [
    { label: 'Company Information', value: 'companyInfo', isActive: true },
    { label: 'Drive Information', value: 'driveInfo', isActive: false },
    // { label: 'Academic Information', value: 'academicInfo', isActive: false },
    { label: 'Profile & Salary Information', value: 'salaryInfo', isActive: false },
    { label: 'Placement Information', value: 'addInfo', isActive: false },
    { label: 'Optional Form', value: 'optForm', isActive: false }
]

export const DateStatus = [
    { label: 'Confirmed Date', value: 'confirmedDate', isActive: true },
    { label: 'Tentative Date', value: 'tentativeDate', isActive: false },
    { label: 'To be Announced', value: 'toBeAnnounced', isActive: false }
]

export const ActionOptions = [
    { label: 'clone', value: 'clone' },
    { label: 'delete', value: 'Delete' }
]


export enum MoveStudentsHeader {
    'moveTo' = 'Move Stage',
    'markAs' = 'Mark Student'
};

export const EBtnProperties = {
    singleProfile: {
        btnName: 'Single Profile Drive',
        fontColor: 'var(--clr-white)',
        background: 'var(--clr-primary)',
        border: 'unset',
        width: '13vw',
        fontWeight: 'bold',
        fontSize: '14px',
        height: '38px',
        borderRadius: '4px',
        marginleft: '0px',
        padding: '0px 10px',
        cursor: 'pointer'
    },
    multiProfile: {
        btnName: 'Multi Profile Drive',
        fontColor: 'var(--clr-white)',
        background: 'var(--clr-primary)',
        border: 'unset',
        width: '13vw',
        fontWeight: 'bold',
        fontSize: '14px',
        height: '38px',
        borderRadius: '4px',
        marginleft: '0px',
        padding: '0px 10px',
        cursor: 'pointer'
    },
    cancelDrive: {
        btnName: 'Cancel',
        fontColor: 'var(--clr-primary)',
        background: 'var(--clr-white)',
        border: '1px solid var(--clr-primary)',
        width: '100%',
        fontWeight: 'bold',
        fontSize: '14px',
        height: '100%',
        borderRadius: '4px',
        marginleft: '0px',
        padding: '0px 10px',
        cursor: 'pointer'
    },
    publishDrive: {
        btnName: 'Publish',
        fontColor: 'var(--clr-white)',
        background: 'var(--clr-primary)',
        border: 'unset',
        width: '100%',
        fontWeight: 'bold',
        fontSize: '14px',
        height: '100%',
        borderRadius: '4px',
        marginleft: '0px',
        padding: '0px 10px',
        cursor: 'pointer'
    },
    saveDriveEligibility: {
        btnName: 'Save',
        fontColor: 'var(--clr-primary)',
        background: 'var(--clr-white)',
        border: '1px solid var(--clr-primary)',
        width: '100%',
        fontWeight: 'bold',
        fontSize: '14px',
        height: '100%',
        borderRadius: '4px',
        marginleft: '0px',
        padding: '0px 10px',
        cursor: 'pointer'
    },
    rePublishDrive: {
        btnName: 'Republish',
        fontColor: 'var(--clr-white)',
        background: 'var(--clr-primary)',
        border: 'unset',
        width: '100%',
        fontWeight: 'bold',
        fontSize: '14px',
        height: '100%',
        borderRadius: '4px',
        marginleft: '0px',
        padding: '0px 10px',
        cursor: 'pointer'
    },
    undoOnHold: {
        btnName: 'Undo Onhold',
        fontColor: 'var(--clr-white)',
        background: 'var(--clr-primary)',
        border: 'unset',
        width: '100%',
        fontWeight: 'bold',
        fontSize: '14px',
        height: '100%',
        borderRadius: '4px',
        marginleft: '0px',
        padding: '0px 10px',
        cursor: 'pointer'
    },
    onHoldDrive: {
        btnName: 'On Hold',
        fontColor: 'var(--clr-secondary)',
        background: 'var(--clr-white)',
        border: '1px solid var(--clr-secondary)',
        width: '100%',
        fontWeight: 'bold',
        fontSize: '14px',
        height: '100%',
        borderRadius: '4px',
        marginleft: '0px',
        padding: '0px 10px',
        cursor: 'pointer'
    },
    endDrive: {
        btnName: 'End Drive',
        fontColor: 'var(--clr-white)',
        background: 'var(--clr-red-star)',
        border: 'unset',
        width: '100%',
        fontWeight: 'bold',
        fontSize: '14px',
        height: '100%',
        borderRadius: '4px',
        marginleft: '0px',
        padding: '0px 10px',
        cursor: 'pointer'
    },
    emailTemplate: {
        btnName: 'Save',
        fontColor: 'var(--clr-white)',
        background: 'var(--clr-primary)',
        border: 'unset',
        width: '100%',
        fontWeight: 'bold',
        fontSize: '14px',
        height: '100%',
        borderRadius: '4px',
        marginleft: '0px',
        padding: '0px 10px',
        cursor: 'pointer'
    },
    saveDrive: {
        btnName: 'Save',
        fontColor: 'var(--clr-white)',
        background: 'var(--clr-primary)',
        border: 'unset',
        width: '100%',
        fontWeight: 'bold',
        fontSize: '14px',
        height: '100%',
        borderRadius: '4px',
        marginleft: '0px',
        padding: '0px 10px',
        cursor: 'pointer'
    },
    save: {
        btnName: 'Save',
        fontColor: 'var(--clr-white)',
        background: 'var(--clr-primary)',
        border: 'unset',
        width: '100%',
        fontWeight: 'bold',
        fontSize: '14px',
        height: '100%',
        borderRadius: '4px',
        marginleft: '0px',
        padding: '0px 10px',
        cursor: 'pointer'
    },
    saveAndProceed: {
        btnName: 'Save and Proceed',
        fontColor: 'var(--clr-white)',
        background: 'var(--clr-primary)',
        border: 'unset',
        width: '100%',
        fontWeight: 'bold',
        fontSize: '14px',
        height: '100%',
        borderRadius: '4px',
        marginleft: '0px',
        padding: '0px 10px',
        cursor: 'pointer'
    },
    confirmPass: {
        btnName: 'Confirm',
        fontColor: 'var(--clr-white)',
        background: 'var(--clr-primary)',
        border: 'unset',
        width: '100%',
        fontWeight: 'bold',
        fontSize: '14px',
        height: '100%',
        borderRadius: '4px',
        marginleft: '0px',
        padding: '0px 10px',
        cursor: 'pointer'
    }
}

export const PageLimits = [
    { label: 10, value: 10 },
    { label: 25, value: 25 },
    { label: 50, value: 50 },
    { label: 100, value: 100 }
];

export const DriveStatus = [
    { label: 'Saved', value: 'Saved' },
    { label: 'Upcoming', value: 'Upcoming' },
    { label: 'Ongoing', value: 'Ongoing' },
    { label: 'Hold', value: 'Hold' },
    { label: 'Completed', value: 'Completed' }
]

export const AcademicYear = [
    { label: 'All', value: 'all' },
    { label: '2020-21', value: '2020-21' },
    { label: '2021-22', value: '2021-22' }
]

export const RadioOptions = [
    { label: 'Placement Date', value: 'placementDate' },
    { label: 'Last date to Apply', value: 'lastDate' },
];


export const salaryTypeOptions = [
    { label: 'Annually', value: 'annually' },
    { label: 'Monthly', value: 'monthly' }
]

export const ProfileDesignationOptions = [
    { field: 'profile', header: 'Profile', frozen: true, width: '15vw' },
    { field: 'salaryType', header: 'Salary Type', frozen: false, width: '15vw' },
    { field: 'stipend', header: 'Stipend', frozen: false, width: '20vw' },
    { field: 'salary', header: 'Salary', frozen: false, width: '20vw' },
    { field: 'degreeArray', header: 'Degree / Specialization', frozen: false, width: '30vw' },
]

export const StipendRange = [
    { label: 'Fixed Stipend', value: 'fixed' },
    { label: 'To be Announced', value: 'toBeAnnounced' }
]

export const SalaryRange = [
    { label: 'Fixed Salary', value: 'fixed' },
    { label: 'Range', value: 'range' },
    { label: 'To be Announced', value: 'toBeAnnounced' }
]

export const DownloadDataMapping: any = {
    name: 'Drive Name',
    company_name: 'Company Name',
    drive_number: 'Drive Number',
    campus: 'Campus',
    department: 'Department',
    degree_specialization: 'Degree Specializations',
    passed_out_year: 'Passed Out Year',
    company_location: 'Company Location',
    drive_objective: 'Drive Objective',
    drive_status: 'Drive Status',
    profile_designation: 'Profile Designation',
    drive_type: 'Drive Type',
    lastDate: 'Last Date To Apply',
    placement_date: 'Placement Date',
    eligible_count: `Eligible Count`,
    placed_count: `Placed Count`,
    opted_in_count: `Opted In Count`,
    opted_out_count: `Opted Out Count`,
    salary: 'Salary',
    stipend: 'Stipend',
    createdAt: 'Created At',
    createdBy: 'Created By',
    updatedAt: 'Updated At',
    updatedBy: 'Updated By',
    registration: 'Registration',
    company_category: 'Company Category'
};
