import { Component, OnInit } from '@angular/core';
import { Router, Event, NavigationEnd, NavigationStart } from '@angular/router';
import { GlobalService, HttpService, SettingsService } from '@services';
import { NgDynamicBreadcrumbService } from 'ng-dynamic-breadcrumb';
import { MessageService } from 'primeng/api';
import { Severity, Summary, Message, Breadcrumb } from '@enum';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  providers: [MessageService]
})
export class AppComponent implements OnInit {
  path: any = '';
  accountData: any = localStorage.getItem('account_details');
  timeOut: any

  constructor(
    private router: Router,
    private globalService: GlobalService,
    private messageService: MessageService,
    private httpService: HttpService,
    private breadCrumbService: NgDynamicBreadcrumbService,
    private settingsService: SettingsService,
  ) {
    this.accountData = JSON.parse(this.accountData)
    this.routerEvents();
    this.tokenValidate();
    window.addEventListener('offline', () => {
      this.showNetworkStatus();
    });
    window.addEventListener('online', () => {
      clearTimeout(this.timeOut)
    })
  }

  async ngOnInit() {
    this.showNotification();

    // if the page is login page, redirect to login page
    if (window.location.hostname === environment.loginPage) {
      this.handleLoginPageRedirect();
      return;
    };

    // handle already logged in user try to login different domain
    if (!this.globalService.validateDomain()) {
      this.globalService.redirectToLoginPage();
      return;
    }

    // handle session restoration
    if(!this.globalService.isAuthenticated()) {
      this.handleSessionRestoration();
      return;
    };

    let domainRes = await this.httpService.get('/domainValidation');
    if (!domainRes.success) {
      this.messageService.add({ severity: Severity.ERROR, summary: 'Invalid Domain', detail: 'The domain you entered is not valid. Please reach out to your administrator for assistance' });
      return;
    };

    this.globalService.accountDetailSubject.next(domainRes.data);
    this.updateAccountDetails(domainRes);
  };

  // redirect to college page if the user is logged in
  // subdomain is the key to redirect to the user logged college page
  private handleLoginPageRedirect() {
    const subdomain = this.globalService.getCookie('subdomain');
    if (subdomain) {
      window.location.href = this.globalService.redirectUrl(subdomain, 'students');
    };
  };

  // user cookies will set during login
  // get those cookies and restore the session, if any cookies are not found then redirect to login page
  private handleSessionRestoration() {
    const token = this.globalService.getCookie('token');
    const userDetails = this.globalService.getCookie('user_details');
    const accountDetails = this.globalService.getCookie('account_details');
    const refreshToken = this.globalService.getCookie('refresh_token');

    if (token && userDetails && accountDetails && refreshToken) {
      return this.restoreSessionFromCookies(token, userDetails, accountDetails, refreshToken);
    } 
    
    this.clearCookiesAndRedirect();
  };

  private restoreSessionFromCookies(token: string, userDetails: string, accountDetails: string, refreshToken: string) {
    this.globalService.setLocalStorage('user_details', userDetails);
    this.globalService.setLocalStorage('account_details', JSON.stringify(JSON.parse(accountDetails)));
    this.globalService.setTokens(token, refreshToken);
    this.globalService.clearCookies();
  };

  private clearCookiesAndRedirect() {
    this.globalService.clearCookies();
    this.globalService.deleteCookie('subdomain');
    this.globalService.redirectToLoginPage();
  };


  showNetworkStatus() {
    this.timeOut = setTimeout(() => {
      this.messageService.add({severity: Severity.ERROR, summary: Summary.NETWORK, detail: Message.OFFLINE});
    }, 3000)
  }

  private async updateAccountDetails(domainRes: any) {
    const accountDetails = this.globalService.getLocalStorage('account_details');
    const accountRes = accountDetails ? JSON.parse(accountDetails) : {};
    if (accountRes.group_account_id === domainRes.data.group_account_id) {
      localStorage.setItem('account_details', JSON.stringify(domainRes.data));
    }
    this.fetchAccountMetadata();
  }

  async fetchAccountMetadata() {
    try {
      const accountData = JSON.parse(localStorage.getItem('account_details') ?? '{}');
      const { data, success } = await this.settingsService.fetchAccountMetaData(accountData)
      if (!success || !data) return;
      this.globalService.setAccountDetails(data);
    } catch (error) {
      console.error('Error in fetchAccountMetaData', error)
    }
  }

  private async routeMiddleware(event: Event) {
    if(event instanceof NavigationStart){
      const skipRoutes = ['/login', '/forgot', '/redirect', '/student-resume'];
      if (skipRoutes.includes(event.url)) {
        return;
      }
      const localAcc : any = localStorage.getItem('account_details')
      const accountDetails = JSON.parse(localAcc);
      if(accountDetails)
      {
        await this.fetchAccountMetadata();
      }

      if(this.accountData){
        this.globalService.setgrpAccountImage(this.accountData.group_account_id);
        this.globalService.setTitle(this.accountData.PWA);
      }
    }
  }

  private routerEvents() {
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
        this.routeMiddleware(event);
      }
      if (event instanceof NavigationEnd) {
        const path = event.url;
        this.path = path;
        this.updateBreadCrumb();
      }
    });
  };

  updateBreadCrumb() {
    const routeMap: any = {
        '/jobs': Breadcrumb.JOBS,
        '/students': Breadcrumb.STUDENTS,
        '/eligibility': Breadcrumb.ELIGIBILITY,
        '/email-template': Breadcrumb.EMAILTEMPLATE,
        '/drives': Breadcrumb.DRIVES,
        '/programme-mapping': Breadcrumb.PROGRAMMEMAPPING,
        '/republish-queue': Breadcrumb.REPUBLISHQUEUE,
        '/settings': Breadcrumb.SETTINGS,
        '/message-insights': Breadcrumb.MESSAGE_INSIGHTS,
        '/reminders': Breadcrumb.REMINDERS,
        // '/custom-message': Breadcrumb.CUSTOMMESSAGE,
    };

    const currentRoute = this.router.url;

    if (routeMap.hasOwnProperty(currentRoute)) {
        this.breadCrumbService.updateBreadcrumb([
            { label: routeMap[currentRoute], url: '' }
        ]);
    }
  }

  private showNotification() {
    this.globalService.notification.subscribe(({ message, title }: { message: string, title: string }) => {
      this.messageService.add({ severity: Severity.SUCCESS, summary: message, detail: title });
    });
  }

  private tokenValidate() {
    this.globalService.validToken.subscribe((res: any) => {
      this.messageService.add({ severity: Severity.ERROR, summary: Summary.SESSION, detail: res.message });
    });
  }

  get isMainPage(): boolean {
    return ( 
      this.path && 
      this.path !== '/login' && 
      this.path !== '/' && 
      !this.path.includes('/forgot') && 
      !this.path.includes('redirect') &&
      !this.path.includes('student-resume')
    );
  }
}
